@charset "UTF-8";
@import "variables";
@import "mixin";


#homeSearch { 
	background: #fff;
	border-bottom: 1px solid #e5e5e5;
	padding: 10px 8px 10px;
	@include pc { 
		padding: 20px;
		border-bottom: none;
	}
	.search { 
		background: #f4f3ed;
		border: 1px solid #dcdcdc;
		font-weight: normal;
    padding: 3px 5px;
		width: 75%;
		height: 30px;
    box-sizing: border-box;
		box-shadow: none;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 0;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		float: left;
		@include pc { 
			padding: 13px 13px;
			width: 83%;
			height: 40px;
		}
	}
	.btn { 
		float: right;
	}
	::-webkit-input-placeholder { 
	  color: #dcdcdc;
	  font-size: 1.2rem;
	  @include pc { 
	  	font-size: 1.6rem;
	  }
	}
	::-moz-placeholder { 
	  color: #dcdcdc;
	  opacity: 1;
	  font-size: 1.2rem;
	  @include pc { 
	  	font-size: 1.6rem;
	  }
	}
	:-ms-input-placeholder { 
	  color: #dcdcdc;
	  font-size: 1.2rem;
	  @include pc { 
	  	font-size: 1.6rem;
	  }
	}
	.btn,input[type="submit"] { 
		background-image: url(../img/top/btn_search_sp.png);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 36px;
	  background-color: #ffde26;
		text-align: center;
		padding: 5px 0 0;
		width: 22%;
		height: 30px;
	  border: none;
	  box-sizing: border-box;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		border-radius: 3px;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		box-shadow: none;
		-webkit-box-shadow: inset 0px -3px 0px 0px rgba(219,155,19,1);
		-moz-box-shadow: inset 0px -3px 0px 0px rgba(219,155,19,1);
		box-shadow: inset 0px -3px 0px 0px rgba(219,155,19,1);
		@include pc { 
			background-image: url(../img/top/btn_search_pc.png);
			background-repeat: no-repeat;
			background-position: 28px center;
			background-size: 52px;
			width: 15.2%;
			height: 40px;
			&:hover { 
				box-shadow: none;
				background-position: 28px 14px;
			}
		}
	}
	
	/* form */
}

#homeNews { 
	padding: 9px 15px 35px;
	@include pc { 
		padding: 13px 0 81px;
	}
	h2 { 
		background: url(../img/top/ico03.png) no-repeat left 5px;
		background-size: 19px;
		@include pc { 
			background-size: 27px;
		}
	}
	.box { 
		border: 4px solid #ebe9e3;
		background: #fff;
		padding: 4px 17px;
		letter-spacing: .1rem;
		@include pc { 
			border: 6px solid #ebe9e3;
			background: #fff;
			padding: 10px 34px;
			letter-spacing: .1rem;
		}
		.list { 
			li { 
				border-bottom: 1px solid #edeff1;
				a { 
					padding: 13px 0;
					@include pc { 
						display: table;
						width: 100%;
						padding: 13px 0 18px;
					}
					.date { 
						font-size: 1.3rem;
						color: #f36259;
						font-weight: 600;
						@include pc { 
							font-size: 1.5rem;
							display: table-cell;
							width: 16%;
						}
					}
					.tit { 
						@include pc { 
							display: table-cell;
						}
					}
				}
				&:last-child { 
					border-bottom: none;
				}
			}
		}
	}
}